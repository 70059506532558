var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'px-3',staticStyle:{"gap":"24px"}},[_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 1. 작업 참고사항을 남겨주세요. ")]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 파일 출처 등 내용을 남겨주시면, 담당자가 확인하여 의견을 공유드립니다! ")])]),_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex pa-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center',staticStyle:{"gap":"auto"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1',staticStyle:{"min-width":"120px","height":"100%","white-space":"nowrap","align-content":"center"}},[_vm._v(" 참고사항 ")]),_c('v-input',{staticClass:"font-weight-regular pa-2 d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',style:({
              borderRadius: '4px',
              width: '100%',
              border: '1px solid var(--v-background_dark-base)',
              height: '40px',
            }),attrs:{"hide-details":"auto","dense":"","solo":"","single-line":""},on:{"click":function($event){_vm.memo == _vm.defaultMemo && (_vm.memo = '')}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memo),expression:"memo"}],staticStyle:{"width":"100%"},attrs:{"type":"text"},domProps:{"value":(_vm.memo)},on:{"input":[function($event){if($event.target.composing)return;_vm.memo=$event.target.value},_vm.checkInput]}})]},proxy:true}]),model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})],1)])]),_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 2. 희망하는 출고일을 선택해 주세요. ")]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 영업일과 배송 소요일을 고려하여 희망 출고일을 입력해주세요. ")])]),_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex pa-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center',staticStyle:{"gap":"auto"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1',staticStyle:{"min-width":"120px","height":"100%","white-space":"nowrap","align-content":"center"}},[_vm._v(" 희망 출고일 ")]),_c('v-menu',{style:({
              borderRadius: '4px',
              width: '100%',
              border: '1px solid var(--v-background_dark-base)',
            }),attrs:{"close-on-content-click":false,"nudge-right":_vm.$vuetify.breakpoint.mdAndUp && 40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',staticStyle:{"width":"100%","height":"40px","border":"1px solid var(--v-background_dark-base)","border-radius":"4px","padding":"8px","cursor":"pointer","box-sizing":"border-box","align-content":"center"},on:{"click":_vm.toggleDatepicker}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formattedDueAt)+" ")])]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"min":_vm.today,"show-current":"","show-adjacent-months":"","locale":"ko","flat":"","color":"primary"},on:{"input":function($event){_vm.datepicker = false;
                _vm.orderData.dueAt = _vm.dueAt;}},model:{value:(_vm.dueAt),callback:function ($$v) {_vm.dueAt=$$v},expression:"dueAt"}})],1)],1)])]),_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 3. 희망하는 배송 방법을 선택해 주세요. ")]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 담당자 협의 후 변경 가능합니다. 배송 소요일을 고려하여 선택해주세요! ")])]),_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex pa-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center',staticStyle:{"gap":"auto"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1',staticStyle:{"min-width":"120px","height":"100%","white-space":"nowrap","align-content":"center"}},[_vm._v(" 배송 방법 ")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"12px"}},_vm._l((_vm.shippingMethods),function(item){return _c('div',{key:item.id,staticClass:"d-flex px-4 py-2 justify-center",staticStyle:{"border-radius":"4px","height":"60px","flex-direction":"column","gap":"4px","cursor":"pointer"},style:(_vm.makerSpace[_vm.orderData.workspace].shippingMethods.includes(
                  item.id
                )
                  ? _vm.orderData.shippingMethod == item.id
                    ? 'border: 1px solid var(--v-primary-base)'
                    : 'border: 1px solid var(--v-background_dark-base)'
                  : 'background: rgb(0,0,0,0.1)'),on:{"click":() => {
                  if (
                    _vm.makerSpace[_vm.orderData.workspace].shippingMethods.includes(
                      item.id
                    )
                  ) {
                    _vm.orderData.shippingMethod = item.id;
                    _vm.orderData.shippingPrice = item.price;
                  } else {
                    _vm.$toasted.global.error(
                      '선택된 메이커스페이스에서 지원하지 않는 옵션입니다'
                    );
                  }
                }}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(item.subtitle))])])}),0)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }