<template>
  <div>
    <div
      class="d-flex flex-column"
      :class="$vuetify.breakpoint.smAndDown ? '' : 'px-3'"
      style="gap: 24px"
    >
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            1. 작업 참고사항을 남겨주세요.
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            파일 출처 등 내용을 남겨주시면, 담당자가 확인하여 의견을
            공유드립니다!
          </div>
        </div>
        <div class="px-3">
          <div
            class="d-flex pa-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="gap: auto"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1'
              "
              style="
                min-width: 120px;
                height: 100%;
                white-space: nowrap;
                align-content: center;
              "
            >
              참고사항
            </div>
            <v-input
              v-model="memo"
              hide-details="auto"
              dense
              solo
              single-line
              class="font-weight-regular pa-2 d-flex align-center"
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
              :style="{
                borderRadius: '4px',
                width: '100%',
                border: '1px solid var(--v-background_dark-base)',
                height: '40px',
              }"
              @click="memo == defaultMemo && (memo = '')"
            >
              <template v-slot:default>
                <input
                  type="text"
                  style="width: 100%"
                  @input="checkInput"
                  v-model="memo"
                />
              </template>
            </v-input>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            2. 희망하는 출고일을 선택해 주세요.
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            영업일과 배송 소요일을 고려하여 희망 출고일을 입력해주세요.
          </div>
        </div>
        <div class="px-3">
          <div
            class="d-flex pa-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="gap: auto"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1'
              "
              style="
                min-width: 120px;
                height: 100%;
                white-space: nowrap;
                align-content: center;
              "
            >
              희망 출고일
            </div>
            <v-menu
              v-model="datepicker"
              :close-on-content-click="false"
              :nudge-right="$vuetify.breakpoint.mdAndUp && 40"
              transition="scale-transition"
              :style="{
                borderRadius: '4px',
                width: '100%',
                border: '1px solid var(--v-background_dark-base)',
              }"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="
                    $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleDatepicker"
                  style="
                    width: 100%;
                    height: 40px;
                    border: 1px solid var(--v-background_dark-base);
                    border-radius: 4px;
                    padding: 8px;
                    cursor: pointer;
                    box-sizing: border-box;
                    align-content: center;
                  "
                >
                  {{ formattedDueAt }}
                </div>
              </template>
              <v-date-picker
                v-model="dueAt"
                @input="
                  datepicker = false;
                  orderData.dueAt = dueAt;
                "
                :min="today"
                show-current
                show-adjacent-months
                locale="ko"
                flat
                color="primary"
              />
            </v-menu>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            3. 희망하는 배송 방법을 선택해 주세요.
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            담당자 협의 후 변경 가능합니다. 배송 소요일을 고려하여 선택해주세요!
          </div>
        </div>
        <div class="px-3">
          <div
            class="d-flex pa-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="gap: auto"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2 pb-2' : 'subtitle-1'
              "
              style="
                min-width: 120px;
                height: 100%;
                white-space: nowrap;
                align-content: center;
              "
            >
              배송 방법
            </div>
            <div class="d-flex flex-wrap" style="gap: 12px">
              <div
                :style="
                  makerSpace[orderData.workspace].shippingMethods.includes(
                    item.id
                  )
                    ? orderData.shippingMethod == item.id
                      ? 'border: 1px solid var(--v-primary-base)'
                      : 'border: 1px solid var(--v-background_dark-base)'
                    : 'background: rgb(0,0,0,0.1)'
                "
                style="
                  border-radius: 4px;
                  height: 60px;
                  flex-direction: column;
                  gap: 4px;
                  cursor: pointer;
                "
                class="d-flex px-4 py-2 justify-center"
                @click="
                  () => {
                    if (
                      makerSpace[orderData.workspace].shippingMethods.includes(
                        item.id
                      )
                    ) {
                      orderData.shippingMethod = item.id;
                      orderData.shippingPrice = item.price;
                    } else {
                      $toasted.global.error(
                        '선택된 메이커스페이스에서 지원하지 않는 옵션입니다'
                      );
                    }
                  }
                "
                v-for="item in shippingMethods"
                :key="item.id"
              >
                <div
                  :class="
                    $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
                  "
                >
                  {{ item.title }}
                </div>
                <div class="body-2">{{ item.subtitle }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import constants from "@/utils/constants.js";
export default {
  props: { step: String, orderData: Object },
  data() {
    return {
      memo: "",
      shippingMethods: [
        {
          id: "택배",
          title: "일반 택배 (1~2영업일)",
          subtitle: "기본 CJ 대한통운",
          price: 3500,
        },
        {
          id: "고속버스",
          title: "고속버스 탁송 (당일)",
          subtitle: "지역 터미널 방문 수령",
          price: 20000,
        },
        {
          id: "방문수령",
          title: "방문수령",
          subtitle: "담당자와 협의",
          price: 0,
        },
        {
          id: "퀵",
          title: "퀵 서비스",
          subtitle: "담당자와 협의",
          price: 0,
        },
      ],
      makerSpace: constants.newmakerSpace,

      defaultMemo: "ex) '링크'에서 가져온 모델입니다.",
      datepicker: false,
      today: new Date().toLocaleDateString("en-CA").substr(0, 10),
      dueAt: this.orderData.dueAt
        ? new Date(this.orderData.dueAt).toLocaleDateString("en-CA")
        : this.today,
    };
  },
  mounted() {
    this.memo = this.orderData.memo || this.defaultMemo;
  },
  computed: {
    formattedDueAt() {
      return this.orderData.dueAt
        ? moment(this.orderData.dueAt).format("YYYY-MM-DD")
        : "배송 방법을 고려하여 선택하세요";
    },
  },
  methods: {
    checkInput() {
      if (this.memo) {
        this.orderData.memo = this.memo;
      }
    },
    toggleDatepicker() {
      this.datepicker = !this.datepicker;
    },
  },
};
</script>

<style></style>
