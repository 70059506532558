<template>
  <div>
    <div class="d-flex flex-column" style="gap: 20px">
      <div
        class="d-flex"
        style="gap: 32px"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-3' : ''"
      >
        <div
          v-if="$vuetify.breakpoint.mdAndUp || showOption"
          class="d-flex flex-column"
          style="gap: 20px"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 55%'"
        >
          <file-viewer-card
            v-if="
              $vuetify.breakpoint.mdAndUp ||
              ($vuetify.breakpoint.smAndDown && selectedFile.length == 1)
            "
            :file="selectedFile.length === 1 ? selectedFile[0] : {}"
          />
          <div class="d-flex flex-column" style="gap: 12px">
            <div class="d-flex flex-column" style="gap: 4px">
              <div
                class="font-weight-bold onSurface_dark--text"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'
                "
              >
                {{
                  selectedFile.length === 0
                    ? "선택된 파일이 없습니다."
                    : selectedFile.length === 1
                    ? selectedFile[0].name
                    : `${selectedFile[0].name} 외 ${
                        selectedFile.length - 1
                      }개 파일`
                }}
              </div>
              <div class="subtitle-2 font-weight-regular error--text">
                {{
                  isDuplicateSelected
                    ? "한 파일에 여러 옵션이 존재하는 경우 일괄 수정이 불가합니다."
                    : checkModelSize
                }}
              </div>
              <div
                v-if="selectedFile.length === 1"
                class="px-3 d-flex justify-space-between subtitle-2 font-weight-regular onSuarface_dark--text flex-wrap"
                style="gap: 12px; width: 100%"
              >
                <div
                  class="d-flex flex-column"
                  style="gap: 8px; min-width: 95px"
                >
                  <div>크기 (최외곽)</div>
                  <div class="d-flex flex-column px-2" style="gap: 8px">
                    <div class="d-flex justify-space-between">
                      <div>X:</div>
                      <div>{{ selectedFile[0].dimensions.x }}mm</div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div>Y:</div>
                      <div>{{ selectedFile[0].dimensions.y }}mm</div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div>Z:</div>
                      <div>{{ selectedFile[0].dimensions.z }}mm</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column" style="gap: 8px">
                  <div>모델 크기 조절</div>
                  <div class="px-2 d-flex" style="gap: 4px">
                    <div
                      class="px-4 justify-center align-center div-border"
                      style="height: 40px; cursor: pointer"
                      @click="updateModelScale(0.1)"
                    >
                      ÷ 10배
                    </div>
                    <div
                      class="px-4 justify-center align-center div-border"
                      style="height: 40px; cursor: pointer"
                      @click="updateModelScale(10)"
                    >
                      × 10배
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column" style="gap: 8px">
                  <div>모델 회전</div>
                  <div class="d-flex flex-column px-2" style="gap: 8px">
                    <div class="d-flex justify-space-between" style="gap: 12px">
                      <div class="d-flex align-center">X:</div>
                      <v-btn
                        icon
                        @click="rotate('x', 'cw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-ccw" size="14" />
                      </v-btn>
                      <v-btn
                        icon
                        @click="rotate('x', 'ccw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-cw" size="14" />
                      </v-btn>
                    </div>
                    <div class="d-flex justify-space-between" style="gap: 12px">
                      <div class="d-flex align-center">Y:</div>
                      <v-btn
                        icon
                        @click="rotate('y', 'cw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-ccw" size="14" />
                      </v-btn>
                      <v-btn
                        icon
                        @click="rotate('y', 'ccw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-cw" size="14" />
                      </v-btn>
                    </div>
                    <div class="d-flex justify-space-between" style="gap: 12px">
                      <div class="d-flex align-center">Z:</div>
                      <v-btn
                        icon
                        @click="rotate('z', 'cw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-ccw" size="14" />
                      </v-btn>
                      <v-btn
                        icon
                        @click="rotate('z', 'ccw')"
                        height="16px"
                        width="16px"
                      >
                        <feather type="rotate-cw" size="14" />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 12px"
            v-if="selectedFile.length === 1"
          >
            <div class="subtitle-2 font-weight-bold">옵션 별 수량</div>
            <div class="px-3 d-flex flex-column" style="gap: 4px">
              <div
                class="py-2 justify-space-between justify-center div-border"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'px-2 subtitle-2' : 'px-4'
                "
                style="width: 100%; cursor: pointer"
                :style="
                  option.selected
                    ? 'border: 1px solid var(--v-primary-base);'
                    : null
                "
                v-for="(option, i) in selectedFile[0].option"
                :key="i"
                @click="
                  selectedFile[0].option.forEach((opt, index) => {
                    opt.selected = index === i;
                  })
                "
              >
                <div>
                  {{
                    `${option.method} / ${option.material} / ${option.color} (${option.layer}mm / ${option.wall}겹 / ${option.infill})`
                  }}
                </div>
                <div
                  class="d-flex"
                  :style="
                    $vuetify.breakpoint.smAndDown ? 'gap: 12px' : 'gap: 16px'
                  "
                >
                  <div>{{ option.totalQty + "EA" }}</div>
                  <feather
                    size="22"
                    type="trash"
                    stroke="var(--v-error-base)"
                    style="vertical-align: middle"
                    @click="deleteOption(i)"
                  />
                </div>
              </div>
              <div
                class="d-flex justify-center align-center py-2 subtitle-2 font-weight-bold"
                style="
                  border-radius: 4px;
                  border: 3px dashed var(--v-primary-base);
                "
                @click="addOption()"
              >
                + 새 옵션 추가하기
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            style="gap: 12px"
            v-if="selectedFile.length > 0 && !isDuplicateSelected"
          >
            <div class="subtitle-2 font-weight-bold">출력 수량</div>
            <div class="d-flex px-3 flex-wrap" style="gap: 12px">
              <div
                class="px-4 justify-center align-center div-border"
                style="height: 40px; cursor: pointer"
                :style="
                  selectedOptions.some(
                    (option) => option && option.totalQty === item
                  )
                    ? 'border: 1px solid var(--v-primary-base)'
                    : ''
                "
                v-for="item in [1, 2, 5, 10]"
                :key="item"
                @click="
                  selectedOptions.forEach((option) => {
                    if (option) {
                      option.totalQty = item;
                    }
                  });
                  qtyInputValue = '직접 입력';
                "
              >
                {{ item + "개" }}
              </div>
              <div
                class="px-4 justify-center align-center div-border"
                style="height: 40px; width: 84px"
                :style="
                  ![1, 2, 5, 10].includes(
                    selectedOptions.find((option) => option) &&
                      selectedOptions.find((option) => option).totalQty
                  )
                    ? 'border: 1px solid var(--v-primary-base)'
                    : ''
                "
              >
                <input
                  ref="qtyInput"
                  type="text"
                  style="width: 100%"
                  class="text-end"
                  @input="
                    $event.target.value = $event.target.value.replace(
                      /[^0-9]/g,
                      ''
                    );
                    selectedOptions.forEach((option) => {
                      if (option) {
                        option.totalQty = parseInt($event.target.value) || 1;
                      }
                    });
                    qtyInputValue = $event.target.value;
                  "
                  :value="qtyInputValue"
                  @focus="qtyInputValue === '직접 입력' && (qtyInputValue = '')"
                />
                <span v-if="qtyInputValue != '직접 입력'"> 개 </span>
              </div>
            </div>
          </div>

          <div
            class="d-flex flex-column"
            style="gap: 12px"
            v-if="selectedFile.length > 0 && !isDuplicateSelected"
          >
            <div class="subtitle-2 font-weight-bold">출력 옵션</div>
            <div class="d-flex flex-column px-3" style="gap: 12px">
              <div class="d-flex flex-column" style="gap: 8px">
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  방식을 선택하세요. (차후 변경 가능)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="px-3 py-2 div-border flex-column"
                    style="gap: 4px; cursor: pointer"
                    :style="
                      Object.keys(
                        makerSpace[orderData.workspace].options
                      ).includes(item)
                        ? selectedOptions.some(
                            (option) => option.method === item
                          )
                          ? 'border: 1px solid var(--v-primary-base)'
                          : ''
                        : 'background: rgb(0,0,0,0.1)'
                    "
                    v-for="item in Object.keys(printOption)"
                    :key="item"
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          if (
                            Object.keys(
                              makerSpace[orderData.workspace].options
                            ).includes(item)
                          ) {
                            option.method = item;
                            option.material = Object.keys(
                              printOption[item].materials
                            )[0];
                            option.color = Object.keys(
                              printOption[item].materials[option.material]
                                .colors
                            )[0];
                          } else {
                            $toasted.global.error(
                              '선택된 메이커스페이스에서 지원하지 않는 옵션입니다'
                            );
                          }
                        }
                      })
                    "
                  >
                    <div
                      class="subtitle-1 font-weight-regular onSurface_dark--text"
                    >
                      {{ printOption[item].name }}
                    </div>
                    <div
                      class="body-2 font-weight-regular onSurface_light--text"
                      style="display: inline-block; width: auto"
                    >
                      {{ printOption[item].description }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column" style="gap: 8px">
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  소재를 선택하세요. (차후 변경 가능)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="px-3 py-2 div-border flex-column"
                    style="gap: 4px; cursor: pointer"
                    v-for="(value, item) in printOption[
                      selectedOptions[0].method
                    ].materials"
                    :key="item"
                    :style="
                      Object.keys(
                        makerSpace[orderData.workspace].options[
                          selectedOptions[0].method
                        ]
                      ).includes(item)
                        ? selectedOptions.some(
                            (option) => option.material === item
                          )
                          ? 'border: 1px solid var(--v-primary-base)'
                          : ''
                        : 'background: rgb(0,0,0,0.1)'
                    "
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          if (
                            Object.keys(
                              makerSpace[orderData.workspace].options[
                                selectedOptions[0].method
                              ]
                            ).includes(item)
                          ) {
                            option.material = item;
                            option.color = Object.keys(
                              printOption[option.method].materials[item].colors
                            )[0];
                          } else {
                            $toasted.global.error(
                              '선택된 메이커스페이스에서 지원하지 않는 옵션입니다'
                            );
                          }
                        }
                      })
                    "
                  >
                    <div
                      class="subtitle-1 font-weight-regular onSurface_dark--text"
                      style="
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                      "
                    >
                      {{ item }}
                    </div>
                    <div
                      class="body-2 font-weight-regular onSurface_light--text"
                      style="
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                      "
                    >
                      {{ value.description }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column" style="gap: 8px">
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  색상을 선택하세요. (차후 변경 가능)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="pa-2 div-border flex-column"
                    style="gap: 4px; width: 56px; height: 56px; cursor: pointer"
                    :style="
                      makerSpace[orderData.workspace].options[
                        selectedOptions[0].method
                      ][selectedOptions[0].material].includes(item)
                        ? selectedOptions.some(
                            (option) => option.color === item
                          )
                          ? 'border: 1px solid var(--v-primary-base)'
                          : ''
                        : 'background: rgb(0,0,0,0.1)'
                    "
                    v-for="(value, item) in printOption[
                      selectedOptions[0].method
                    ].materials[selectedOptions[0].material].colors"
                    :key="item"
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          if (
                            makerSpace[orderData.workspace].options[
                              selectedOptions[0].method
                            ][selectedOptions[0].material].includes(item)
                          ) {
                            option.color = item;
                          } else {
                            $toasted.global.error(
                              '선택된 메이커스페이스에서 지원하지 않는 옵션입니다'
                            );
                          }
                        }
                      })
                    "
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          :style="{ backgroundColor: value.hex }"
                          style="width: 100%; height: 100%"
                        />
                      </template>
                      <span> {{ item }} </span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column"
                style="gap: 8px"
                v-if="selectedOptions.some((option) => option.method === 'FDM')"
              >
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  레이어 두께 (기본값 권장)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="px-3 py-2 div-border flex-column"
                    style="gap: 4px; cursor: pointer"
                    v-for="(value, item) in printOption[
                      selectedOptions[0].method
                    ].materials[selectedOptions[0].material].layer"
                    :key="item"
                    :style="
                      selectedOptions.some((option) => option.layer === value)
                        ? 'border: 1px solid var(--v-primary-base)'
                        : ''
                    "
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          option.layer = value;
                        }
                      })
                    "
                  >
                    <div
                      class="subtitle-1 font-weight-regular onSurface_dark--text"
                      style="
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                      "
                    >
                      {{ value + "mm" }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column"
                style="gap: 8px"
                v-if="selectedOptions.some((option) => option.method === 'FDM')"
              >
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  외벽 두께 (기본값 권장)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="px-3 py-2 div-border flex-column"
                    style="gap: 4px; cursor: pointer"
                    v-for="(value, item) in printOption[
                      selectedOptions[0].method
                    ].materials[selectedOptions[0].material].wall"
                    :key="item"
                    :style="
                      selectedOptions.some((option) => option.wall === value)
                        ? 'border: 1px solid var(--v-primary-base)'
                        : ''
                    "
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          option.wall = value;
                        }
                      })
                    "
                  >
                    <div
                      class="subtitle-1 font-weight-regular onSurface_dark--text"
                      style="
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                      "
                    >
                      {{ value + "겹 (" + Math.round(value * 4) / 10 + "mm)" }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-column"
                style="gap: 8px"
                v-if="selectedOptions.some((option) => option.method === 'FDM')"
              >
                <div
                  class="subtitle-2 font-weight-regular onSurface_light--text"
                >
                  내부 밀도 (기본값 권장)
                </div>
                <div class="d-flex flex-wrap" style="gap: 8px">
                  <div
                    class="px-3 py-2 div-border flex-column"
                    style="gap: 4px; cursor: pointer"
                    v-for="(value, item) in printOption[
                      selectedOptions[0].method
                    ].materials[selectedOptions[0].material].infill"
                    :key="item"
                    :style="
                      selectedOptions.some((option) => option.infill === value)
                        ? 'border: 1px solid var(--v-primary-base)'
                        : ''
                    "
                    @click="
                      selectedOptions.forEach((option) => {
                        if (option) {
                          option.infill = value;
                        }
                      })
                    "
                  >
                    <div
                      class="subtitle-1 font-weight-regular onSurface_dark--text"
                      style="
                        display: inline-block;
                        white-space: nowrap;
                        width: auto;
                      "
                    >
                      {{ value }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="$vuetify.breakpoint.smAndDown && showOption"
                class="d-flex pt-5"
              >
                <v-btn
                  class="px-2 subtitle-1"
                  color="primary"
                  height="40px"
                  elevation="0"
                  block
                  @click="showOption = false"
                >
                  옵션 선택 완료
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!showOption"
          class="d-flex flex-column"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'width: 100%; gap: 16px'
              : 'width: 55%; gap: 20px'
          "
        >
          <div class="d-flex flex-column" style="gap: 4px">
            <div
              class="font-weight-bold onSurface_dark--text"
              :class="$vuetify.breakpoint.mdAndUp ? 'title-1' : 'subtitle-1'"
            >
              아래에 파일을 추가하세요.
            </div>
            <div
              class="subtitle-2 px-1 font-weight-regular onSurface_light--text"
            >
              STL(권장), OBJ, 3MF, STP, STEP / 50MB 제한
            </div>
          </div>

          <div
            class="d-flex subtitle-2 onSurface_dark--text justify-space-between align-center"
          >
            <div
              style="cursor: pointer"
              @click="
                const allSelected = orderData.file.every(
                  (item) => item.selected
                );
                orderData.file.forEach(
                  (item) => (item.selected = !allSelected)
                );
              "
            >
              {{
                orderData.file.length != 0
                  ? orderData.file.every((item) => item.selected)
                    ? "전체 선택 해제"
                    : "전체 선택"
                  : "전체 선택"
              }}
            </div>
            <div
              v-if="$vuetify.breakpoint.mdAndUp"
              style="cursor: pointer"
              @click="deleteSelectedFile()"
            >
              선택 항목 삭제
            </div>
            <div
              v-else-if="selectedFile.length > 1"
              class="d-flex"
              style="gap: 12px"
            >
              <v-btn
                class="px-2 subtitle-2"
                color="error"
                height="32px"
                elevation="0"
                @click="deleteSelectedFile()"
              >
                일괄 삭제
              </v-btn>
              <v-btn
                class="px-2 subtitle-2"
                color="primary"
                height="32px"
                elevation="0"
                @click="
                  () => {
                    if (isDuplicateSelected) {
                      $toasted.global.error(
                        '한 파일에 여러 옵션이 있는 경우 일괄 수정이 불가합니다'
                      );
                    } else {
                      showOption = true;
                    }
                  }
                "
              >
                일괄 수정
              </v-btn>
            </div>
          </div>

          <div
            class="d-flex flex-column"
            style="overflow: auto; gap: 4px"
            :style="{
              backgroundColor: dragover ? 'rgba(0, 0, 0, 0.05)' : '',
            }"
            @drop.prevent="onFileDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <div
              v-for="item in orderData.file.filter((v) => !v.delete)"
              :key="item.name"
              class="pa-2 div-border flex-column"
              style="cursor: pointer; gap: 8px"
              :style="
                item.selected && 'border: 1px solid var(--v-primary-base)'
              "
              @click="
                $event.ctrlKey
                  ? (item.selected = !item.selected)
                  : item.selected
                  ? (item.selected = false)
                  : (orderData.file.forEach((i) => (i.selected = false)),
                    (item.selected = true))
              "
            >
              <div class="d-flex" style="gap: 12px">
                <div>
                  <v-skeleton-loader
                    v-if="!previewImage(item)"
                    style="width: 48px; height: 36px"
                    type="image"
                  />
                  <v-img
                    v-else
                    style="border-radius: 4px"
                    :src="previewImage(item)"
                    max-width="48"
                    max-height="36"
                  />
                </div>
                <div
                  class="d-flex flex-column"
                  style="width: 100%"
                  :style="
                    $vuetify.breakpoint.mdAndDown ? 'gap: 4px' : 'gap:8px'
                  "
                >
                  <div class="subtitle-2 font-weight-bold onSurface_dark--text">
                    {{ item.name }}
                  </div>
                  <div
                    v-if="$vuetify.breakpoint.mdAndDown"
                    class="caption font-weight-regular onSurface_light--text"
                  >
                    X: {{ item.dimensions.x }}, Y: {{ item.dimensions.y }}, Z:
                    {{ item.dimensions.z }} (mm)
                  </div>
                  <div
                    v-else
                    class="d-flex flex-column px-2 subtitle-2 font-weight-regular"
                    style="gap: 4px"
                  >
                    <div
                      v-for="(option, i) in item.option"
                      :key="i"
                      class="d-flex"
                    >
                      <div class="onSurface_normal--text">
                        {{
                          `${option.method} / ${option.material} / ${option.color} (${option.layer} / ${option.wall} / ${option.infill})`
                        }}
                      </div>
                      <v-spacer />
                      <div class="onSurface_dark--text">
                        {{ option.totalQty + "EA" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="$vuetify.breakpoint.mdAndDown"
                class="d-flex flex-column px-2 subtitle-2 font-weight-regular"
                style="gap: 4px"
              >
                <div v-for="(option, i) in item.option" :key="i" class="d-flex">
                  <div class="onSurface_normal--text">
                    {{
                      `${option.method} / ${option.material} / ${option.color} (${option.layer} / ${option.wall} / ${option.infill})`
                    }}
                  </div>
                  <v-spacer />
                  <div class="onSurface_dark--text">
                    {{ option.totalQty + "EA" }}
                  </div>
                </div>
              </div>
              <div
                v-if="
                  $vuetify.breakpoint.smAndDown &&
                  selectedFile.length == 1 &&
                  item.selected
                "
                class="pt-1 d-flex justify-space-between"
              >
                <v-btn
                  color="error"
                  class="px-2 subtitle-2"
                  height="32px"
                  elevation="0"
                  @click="deleteSelectedFile()"
                >
                  삭제
                </v-btn>
                <v-btn
                  color="primary"
                  class="px-2 subtitle-2"
                  height="32px"
                  elevation="0"
                  @click="showOption = true"
                >
                  옵션 수정
                </v-btn>
              </div>
            </div>
            <div
              class="d-flex"
              :class="$vuetify.breakpoint.mdAndDown && 'flex-column'"
              style="gap: 4px"
            >
              <div
                class="px-3 py-2 d-flex flex-column justify-center"
                style="
                  cursor: pointer;
                  width: 100%;
                  border: 3px dashed var(--v-primary-base);
                  gap: 4px;
                  border-radius: 4px;
                "
                @click="$refs.uploader.click()"
              >
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileInput"
                  multiple
                />
                <div
                  class="subtitle-2 font-weight-bold onSurface_dark--text d-flex justify-center"
                >
                  + 새 파일 업로드
                </div>
                <div
                  class="subtitle-2 font-weight-regular onSurface_normal--text d-flex justify-center text-center"
                >
                  클릭 또는 드래그로 파일 업로드
                </div>
              </div>
              <div
                class="px-3 py-2 d-flex flex-column justify-center"
                style="
                  cursor: pointer;
                  width: 100%;
                  border: 3px dashed var(--v-primary-base);
                  gap: 4px;
                  border-radius: 4px;
                "
                @click="$toasted.global.error('아직 준비중인 기능입니다.')"
              >
                <div
                  class="subtitle-2 font-weight-bold onSurface_dark--text d-flex justify-center"
                >
                  + 기존 파일 업로드
                </div>
                <div
                  class="subtitle-2 font-weight-regular onSurface_normal--text d-flex justify-center text-center"
                >
                  계정에 저장된 파일 불러오기
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileViewerCard from "@/components/Preview/FileViewerCard.vue";
import constants from "@/utils/constants.js";
import {
  getModelDimensions,
  getModelVolume,
  getModelArea,
  scaleModel,
  rotateModel,
  createPreviewImage,
} from "@/API/modelUtils.js";

export default {
  components: { FileViewerCard },
  props: { step: String, orderData: Object },
  data() {
    return {
      showOption: false,

      qtyInputValue: "직접 입력", // 초기값을 '직접 입력'으로 설정
      selectedOptions: [], // 초기 값
      isDuplicateSelected: false, // 초기 값
      // 파일 추가를 위한 변수
      printOption: constants.newPrintOption,
      makerSpace: constants.newmakerSpace,
      dragover: false,
      modelDimensions: { x: 0, y: 0, z: 0 }, // 모델 크기 저장용
      inputTimeout: null, // 타이머를 저장하기 위한 변수
    };
  },
  computed: {
    selectedFile() {
      return this.orderData.file.filter((file) => file.selected);
    },
    checkModelSize() {
      if (
        ["png", "jpg", "jpeg", "pdf", "ppt"].includes(
          this.selectedFile[0]?.name
            .slice(this.selectedFile[0].name.lastIndexOf(".") + 1)
            .toLowerCase()
        )
      )
        return "";
      if (
        this.selectedFile.some(
          (file) =>
            file.dimensions &&
            (file.dimensions.x > 310 ||
              file.dimensions.y > 310 ||
              file.dimensions.z > 200)
        )
      ) {
        return "모델 크기가 출력 가능 크기를 초과합니다.";
      } else if (
        this.selectedFile.some(
          (file) =>
            file.dimensions &&
            parseFloat(file.dimensions.x) +
              parseFloat(file.dimensions.y) +
              parseFloat(file.dimensions.z) <
              30
        )
      ) {
        return "모델 크기가 너무 작습니다. 다시 확인해주세요.";
      }
      return "";
    },
  },
  watch: {
    selectedFile: {
      immediate: true,
      deep: true,
      handler(newSelectedFiles) {
        // qtyInputValue 업데이트
        const selectedOption = newSelectedFiles
          .map((file) => file.option?.find((v) => v.selected))
          .find((option) => option && ![1, 2, 5, 10].includes(option.totalQty));

        if (selectedOption) {
          this.qtyInputValue = selectedOption.totalQty;
        }

        // selectedOptions 계산 및 업데이트
        this.selectedOptions = newSelectedFiles.map((file) =>
          file.option?.find((v) => v.selected)
        );

        // isDuplicateSelected 계산 및 업데이트
        this.isDuplicateSelected =
          newSelectedFiles.length > 1 &&
          newSelectedFiles.some((file) => file.option.length > 1);
      },
    },
  },
  methods: {
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    async addFile(v) {
      const fileType = v.name.slice(v.name.lastIndexOf(".") + 1).toLowerCase();
      if (
        ["3dm", "skp", "f3d", "igs", "gcode", "sldprt", "ply", "hfb"].includes(
          fileType
        )
      ) {
        this.$toasted.global.error(
          fileType + " 파일은 확인이 불가합니다. STL 파일로 올려주세요!"
        );
        return;
      }
      if (v.size >= 50000000) {
        this.$toasted.global.error(`50MB 이하 파일만 등록 가능합니다.`);
        return;
      }
      const fileNameExists = this.orderData.file.some(
        (file) => file.name === v.name
      );
      if (fileNameExists) {
        this.$toasted.global.error(`이미 같은 이름의 파일이 존재합니다.`);
        return;
      }
      const file = {
        selected: true,
        id: "",
        name: v.name,
        size: v.size,
        type: fileType,
        url: URL.createObjectURL(v),
        dimensions: { x: 0, y: 0, z: 0, v: 0, a: 0 },
        previewImg: "",
        print: false,
        option: [
          {
            selected: true,
            method: "FDM", // printMethod
            material: "PLA", // printMaterial
            color: "White", // printColor
            layer: "0.2", // layer Height
            wall: "2", // wall Count
            infill: "20%", // infill Density
            totalQty: 1, // print Quantity
            finishQty: 0, // 완료된 수량
            price: 0, // 옵션 가격
          },
        ],
      };
      this.orderData.file = this.orderData.file.map((file) => ({
        ...file,
        selected: false,
      }));
      this.orderData.file.unshift(file);
      if (["stl", "obj", "3mf"].includes(fileType)) {
        this.updateModelDetails(file);
      }
    },
    previewImage(item) {
      if (item.previewImg) {
        return item.previewImg;
      } else {
        const fileType = item?.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp", "webp"].includes(fileType)) {
          return item.url;
        }
        if (fileType == "pdf") {
          return "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FPDF_thumbnail.png?alt=media&token=1cc80cdb-6e88-432d-b7ba-e8a0b6ac8c80";
        }
        return null;
      }
    },
    async updateModelDetails(file) {
      const dimensions = await getModelDimensions(file);
      const volume = await getModelVolume(file);
      const area = await getModelArea(file);
      const previewImg = await createPreviewImage(file);
      const fileToUpdate = this.orderData.file.find(
        (f) => f.name === file.name
      );
      if (fileToUpdate) {
        fileToUpdate.previewImg = previewImg;
        fileToUpdate.dimensions = dimensions;
        fileToUpdate.dimensions.v = volume;
        fileToUpdate.dimensions.a = area;
      }
    },
    async updateModelScale(scaleFactor) {
      const file = this.orderData.file.find((v) => v.selected);
      try {
        const newFile = await scaleModel(file, scaleFactor);
        this.$set(file, "url", newFile);
        const dimensions = await getModelDimensions(file);
        const previewImg = await createPreviewImage(file);
        file.previewImg = previewImg;
        file.dimensions = dimensions;
      } catch (error) {
        this.$toasted.global.error("해당 확장자는 지원하지 않는 기능입니다");
        console.error("Error scaling model:", error);
      }
    },

    async rotate(axis, rotation) {
      const file = this.orderData.file.find((v) => v.selected);
      try {
        const newFile = await rotateModel(file, axis, rotation);
        this.$set(file, "url", newFile);
        const dimensions = await getModelDimensions(file);
        const previewImg = await createPreviewImage(file);
        file.previewImg = previewImg;
        file.dimensions = dimensions;
      } catch (error) {
        this.$toasted.global.error("해당 확장자는 지원하지 않는 기능입니다");
        console.error("Error rotating model:", error);
      }
    },

    async deleteSelectedFile() {
      this.orderData.file.forEach((item) => {
        if (item.selected) {
          item.delete = true;
          item.selected = false;
        }
      });
      this.orderData.file[0].selected = true;
      this.orderData.file.slice();
    },
    deleteOption(index) {
      const fileOptions = this.selectedFile[0].option;
      if (fileOptions.length > 1) {
        fileOptions.splice(index, 1);
      } else {
        this.$toasted.global.error("옵션은 최소 1개가 있어야 합니다.");
      }
    },
    addOption() {
      const selected = this.selectedFile[0];
      if (!selected) {
        return this.$toasted.global.error(
          "옵션을 추가할 파일이 선택되지 않았습니다."
        );
      }
      const currentSelectedOption = selected.option.find((v) => v.selected);
      selected.option.forEach((v) => (v.selected = false));
      const newOption = {
        ...currentSelectedOption,
        selected: true,
        method: currentSelectedOption?.method || "FDM",
        material: currentSelectedOption?.material || "PLA",
        color: currentSelectedOption?.color || "White",
        layer: currentSelectedOption?.layer || "0.2",
        wall: currentSelectedOption?.wall || "2",
        infill: currentSelectedOption?.infill || "20%",
        totalQty: currentSelectedOption?.totalQty || 1,
        finishQty: 0,
        price: 0,
      };
      selected.option.unshift(newOption);
    },
  },
};
</script>

<style>
.div-border {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--v-background_dark-base);
}
</style>
