<template>
  <div>
    <div
      class="d-flex flex-column"
      :class="$vuetify.breakpoint.smAndDown ? '' : 'px-3'"
      style="gap: 20px"
    >
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            1. 어떤 작업인가요? <span class="caption error--text">*</span>
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            사용 목적을 참고하여 담당자가 출력 방식이나 소재가 적절한지
            안내드립니다!
          </div>
        </div>
        <div
          class="d-flex px-5 py-2"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <div
            class="d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'"
            style="min-width: 120px; white-space: nowrap"
            :style="
              $vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px'
            "
          >
            사용 목적 (환경)
          </div>
          <div class="d-flex flex-wrap" style="gap: 12px">
            <div
              :style="
                orderData.purpose == purpose
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="border-radius: 4px; height: 40px; cursor: pointer"
              class="d-flex align-center px-4 py-2"
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
              @click="changePurpose(purpose)"
              v-for="purpose in purposes"
              :key="purpose"
            >
              {{ purpose }}
            </div>
            <v-input
              v-model="purposeEtc"
              hide-details="auto"
              dense
              solo
              single-line
              class="font-weight-regular px-4 py-2 d-flex align-center"
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
              :style="{
                borderRadius: '4px',
                maxWidth: $vuetify.breakpoint.smAndDown ? '260px' : '320px',
                height: '40px',
                border:
                  purposeEtc != '기타(직접 입력)'
                    ? '1px solid var(--v-primary-base)'
                    : '1px solid var(--v-background_dark-base)',
              }"
              @click="
                purposeEtc == '기타(직접 입력)' &&
                  ((purposeEtc = ''), (orderData.purpose = ''))
              "
            >
              <template v-slot:default>
                <input
                  type="text"
                  style="width: 100%"
                  @input="checkInput"
                  v-model="purposeEtc"
                />
              </template>
            </v-input>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            2. 3D 도면 파일이 있나요? <span class="caption error--text">*</span>
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            프린팅은 도면이 있어야 진행이 가능합니다. 도면이 없다면, 도면부터
            만들어야 합니다!
          </div>
        </div>
        <div
          class="d-flex px-5 py-2"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <div
            class="subtitle-2 d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'"
            style="width: 120px; height: 40px; white-space: nowrap"
            :style="
              $vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px'
            "
          >
            도면 여부
          </div>
          <div
            class="d-flex"
            :class="$vuetify.breakpoint.smAndDown && 'flex-column'"
            style="gap: 12px"
            :style="$vuetify.breakpoint.smAndDown && 'width: 100%'"
          >
            <div
              :style="
                orderData.workspace != '모델링'
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="border-radius: 4px; gap: 4px; cursor: pointer"
              class="d-flex flex-column px-4 py-3"
              @click="orderData.workspace = '메이드올 대구지사'"
            >
              <div
                :class="
                  $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
                "
              >
                네, 3D 설계 데이터가 있습니다.
              </div>
              <div class="body-2">STL (권장), 3MF, OBJ, STEP, STP 등</div>
            </div>
            <div
              :style="
                orderData.workspace == '모델링'
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="border-radius: 4px; gap: 4px; cursor: pointer"
              class="d-flex flex-column px-4 py-3"
              @click="orderData.workspace = '모델링'"
            >
              <div
                :class="
                  $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
                "
              >
                아니요, 도면 설계부터 필요합니다.
              </div>
              <div class="body-2">참고사진, 도면 스케치, 2D 캐드 도면 등</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="orderData.workspace != '모델링'"
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            3. 작업 장소를 선택해주세요.
            <span class="caption error--text">*</span>
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            각 작업 장소 별 사용 가능한 소재나 방식 등을 참고하시어 이용해
            주세요!
          </div>
        </div>
        <div
          class="d-flex px-5 py-2"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
        >
          <div
            class="d-flex align-center"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'"
            style="min-width: 120px; height: 40px; white-space: nowrap"
            :style="
              $vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px'
            "
          >
            메이커스페이스
          </div>
          <div class="d-flex flex-column" style="gap: 12px; width: 100%">
            <div
              v-for="workspace in workspaces"
              :key="workspace.name"
              :style="
                orderData.workspace == workspace.name
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="
                border-radius: 4px;
                gap: 12px;
                width: 100%;
                cursor: pointer;
              "
              class="d-flex px-4 py-3"
              @click="changeWorkspace(workspace)"
            >
              <div v-if="!$vuetify.breakpoint.smAndDown">
                <v-skeleton-loader
                  v-if="!workspace.img"
                  style="width: 56px; height: 56px"
                  type="image"
                />
                <v-img
                  v-else
                  :src="workspace.img"
                  max-width="56"
                  max-height="56"
                />
              </div>
              <div class="d-flex flex-column" style="gap: 8px; width: 100%">
                <div style="gap: 8px">
                  <div>
                    <div class="d-flex" style="justify-content: space-between">
                      <div
                        :class="
                          $vuetify.breakpoint.smAndDown
                            ? 'subtitle-2'
                            : 'subtitle-1'
                        "
                      >
                        {{ workspace.name }}
                      </div>
                      <div
                        class="subtitle-2"
                        @click="$toasted.global.notice('게시글 준비중입니다')"
                      >
                        자세히 보기 +
                      </div>
                    </div>
                    <div
                      :class="
                        $vuetify.breakpoint.smAndDown ? 'caption' : 'body-2'
                      "
                    >
                      {{ workspace.address }}
                    </div>
                  </div>
                </div>
                <div
                  :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2'"
                >
                  {{ workspace.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { step: String, orderData: Object },
  data() {
    return {
      purposes: [
        "실내 전시물 (대학 과제 등)",
        "차량 용품",
        "캠핑 용품",
        "가정 용품",
        "실내 취미",
        "운동 용품",
        "기업 시제품",
        "학생 모델링 작품",
        "발명대회 시제품",
      ],
      purposeEtc: "기타(직접 입력)",
      modelFile: null,
      workspaces: [
        {
          img: "",
          name: "메이드올 대구지사",
          address: "대구 남구 봉덕로 84 아백빌딩 4층",
          description:
            "민간 업체로, FDM SLA 등 여러 제조방식과 다양한 소재를 지원합니다. 택배나 퀵 서비스가 가능하며 카드나 계산서 등 모든 결제방식이 가능합니다.",
        },
        {
          img: "",
          name: "경희대학교 국제캠퍼스",
          address: "용인시 기흥구 덕영대로 1732 학생회관 1층",
          description:
            "교내 시설로, 재학생을 위한 시설입니다. 외부인도 사용이 가능하나 단일 소재만 작업이 가능합니다. 방문수령 및 학교 기부금으로 결제합니다.",
        },
      ],
    };
  },
  mounted() {
    if (
      this.orderData.purpose &&
      !this.purposes.includes(this.orderData.purpose)
    )
      this.purposeEtc = this.orderData.purpose;
  },
  methods: {
    changePurpose(item) {
      this.purposeEtc = "기타(직접 입력)";
      this.orderData.purpose = item;
    },
    checkInput() {
      if (this.purposeEtc) {
        this.orderData.purpose = this.purposeEtc;
      }
    },
    changeWorkspace(workspace) {
      this.orderData.workspace = workspace.name;
      switch (workspace.name) {
        case "메이드올 대구지사":
          this.orderData.shippingMethod = "택배";
          this.orderData.shippingPrice = 3500;
          this.orderData.paymentMethod = "네이버 결제";
          break;
        case "경희대학교 국제캠퍼스":
          this.orderData.shippingMethod = "방문수령";
          this.orderData.shippingPrice = 0;
          this.orderData.paymentMethod = "현금 결제";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
