<template>
  <div
    style="
      position: relative;
      background: var(--v-background_normal-base);
      box-sizing: border-box;
    "
    :style="
      $vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px'
    "
  >
    <div style="z-index: 0; position: absolute; width: 100vw">
      <v-img
        :src="require('@/assets/images/MyPage/MyPageBackground.jpg')"
        max-height="433px"
        min-height="300px"
        gradient="to top, rgba(0,0,0,0.6), rgba(19,42,86,0.6)"
      />
    </div>
    <v-container
      fluidd
      pa-0
      class="d-flex"
      style="max-width: 1380px !important"
    >
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'mx-10' : 'mx-4'"
        style="padding-bottom: 60px"
        :style="
          $vuetify.breakpoint.lgAndUp
            ? 'padding-top: 100px'
            : $vuetify.breakpoint.smAndUp
            ? 'padding-top: 80px'
            : 'padding-top: 60px'
        "
        color="transparent"
        width="100%"
        flat
      >
        <div class="d-flex-column">
          <div
            class="font-weight-bold white_light_global--text"
            :class="$vuetify.breakpoint.lgAndUp ? 'h1' : 'h2'"
          >
            쉽고 편한 3D프린팅
          </div>
          <div
            class="font-weight-bold white_light_global--text mt-5"
            :class="$vuetify.breakpoint.lgAndUp ? 'title-1 ' : 'subtitle-1'"
            style="max-width: 480px"
          >
            전국 각지 메이커스페이스 네트워크 구축을 통해 언제 어디서나 일관된
            3D프린팅 서비스를 제공합니다.
          </div>
        </div>
        <div
          :style="
            $vuetify.breakpoint.lgAndUp
              ? 'height: 80px'
              : $vuetify.breakpoint.smAndUp
              ? 'height: 60px'
              : 'height: 40px'
          "
        />
        <v-card-text class="pa-0">
          <div
            style="
              z-index: 1;
              position: relative;
              width: 100%;
              display: flex;
              gap: 36px;
            "
          >
            <form-section :step.sync="step" :orderData.sync="orderData" />
            <sidebar v-if="$vuetify.breakpoint.lgAndUp" :step="step" />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <WarningDialog
      :msg="alert"
      :visible.sync="alert.dialog"
      @confirm="alert.onConfirm"
      @cancel="alert.onCancel"
    />
  </div>
</template>

<script>
import setMeta from "@/utils/setMeta";
import Sidebar from "./Components/Sidebar.vue";
import FormSection from "./Components/FormSection.vue";
import constants from "@/utils/constants";
export default {
  components: { Sidebar, FormSection },
  data() {
    return {
      step: "주문자 정보",
      orderData: constants.newOrderFormInterface,
      alert: {
        icon: "alert-triangle",
        dialog: false,
        msg: "",
        confirmMsg: "",
        cancelMsg: "",
        onConfirm: () => {},
        onCancel: () => {},
      },
    };
  },
  beforeCreate() {
    setMeta({
      title: "주문 접수",
      description: "메이드올 제조 서비스 주문 접수 페이지입니다.",
    });
  },
  mounted() {
    const user = this.$store.getters["auth/user/GET_USER"];
    if (user) {
      this.orderData.displayName = user.displayName;
      this.orderData.phoneNumber = `0${user.phoneNumber.slice(3)}`;
      this.orderData.uid = user.uid;
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    // 예: orderData 안에 orderId가 있으면 이미 생성된 주문서라고 가정
    if (this.orderData.id && to.path != "/mypage") {
      this.alert = {
        dialog: true,
        msg: "주문이 끝까지 완료되지 않았습니다!",
        onConfirm: () => next(false),
        confirmMsg: "이어서 하기",
        onCancel: () => next(),
        cancelMsg: "나중에 하기",
      };
    } else {
      next();
    }
  },
};
</script>
