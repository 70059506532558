<template>
  <v-card
    min-width="260px"
    class="pa-6 d-flex"
    style="border-radius: 16px; flex-direction: column; gap: 24px"
  >
    <div class="d-flex" style="flex-direction: column; gap: 24px">
      <div class="title-1 font-weight-bold">주문 절차</div>
      <div class="d-flex" style="flex-direction: column; gap: 12px">
        <div
          class="title-2 font-weight-bold"
          :class="step == '주문자 정보' ? 'primary--text' : ''"
        >
          1.주문자 정보 입력
        </div>
        <div
          class="title-2 font-weight-bold"
          :class="step == '기본 정보' ? 'primary--text' : ''"
        >
          2.기본 정보 입력
        </div>
        <div
          class="title-2 font-weight-bold"
          :class="
            step == '파일 업로드' || step == '스케치 업로드'
              ? 'primary--text'
              : ''
          "
        >
          3.파일 확인 및 업로드
        </div>
        <div
          class="title-2 font-weight-bold"
          :class="step == '추가 정보' ? 'primary--text' : ''"
        >
          4.추가 정보 입력
        </div>
        <div
          class="title-2 font-weight-bold"
          :class="step == '접수 완료' ? 'primary--text' : ''"
        >
          5.주문서 업로드
        </div>
      </div>
    </div>
    <v-spacer />
    <div class="d-flex" style="flex-direction: column; gap: 24px">
      <div class="title-1 font-weight-bold">견적 금액</div>
      <div class="px-3 d-flex" style="flex-direction: column; gap: 20px">
        <div class="d-flex" style="flex-direction: column; gap: 12px">
          <div class="title-2 font-weight-bold">AI 예상 견적</div>
          <div class="subtitle-1" style="width: 100%; text-align: right">
            0원
          </div>
        </div>
        <div class="d-flex" style="flex-direction: column; gap: 12px">
          <div class="title-2 font-weight-bold">최종 견적</div>
          <div class="subtitle-1" style="width: 100%; text-align: right">
            담당자 확인 전
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: { step: String },
};
</script>

<style></style>
