<template>
  <div>
    <div
      class="d-flex flex-column"
      :class="$vuetify.breakpoint.smAndDown ? '' : 'px-3'"
      style="gap: 20px"
    >
      <div class="d-flex flex-column" style="gap: 20px">
        <div class="d-flex flex-column" style="gap: 8px">
          <div class="d-flex">
            <div
              class="font-weight-bold"
              :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
            >
              1. 기본 정보 <span class="caption error--text">*</span>
            </div>
            <v-spacer />
            <div
              v-if="!$firebase.auth().currentUser"
              @click="$router.push('/login')"
              class="subtitle-2 font-weight-bold"
            >
              회원 로그인
              <v-icon size="16px" color="onSurface_dark">
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <div class="px-1 subtitle-2 onSurface_normal--text">
            비회원은 업로드를 제외한 주문 접수 프로세스 둘러보기만 가능합니다.
          </div>
        </div>
        <div class="d-flex flex-column px-3" style="gap: 8px">
          <div
            v-if="$store.getters['auth/user/GET_USER']"
            class="d-flex py-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="max-width: 380px; justify-content: space-between"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
            >
              주문자 성함
            </div>
            <v-text-field
              v-model="orderData.displayName"
              disabled
              flat
              hide-details="auto"
              dense
              solo
              outlined
              single-line
              height="40px"
              style="max-width: 240px"
            />
          </div>
          <div
            v-if="$store.getters['auth/user/GET_USER']"
            class="d-flex py-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="max-width: 380px; justify-content: space-between"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
            >
              주문자 연락처
            </div>
            <v-text-field
              class="align-center"
              v-model="orderData.phoneNumber"
              disabled
              flat
              hide-details="auto"
              dense
              solo
              outlined
              single-line
              height="40px"
              style="max-width: 240px"
            />
          </div>
          <div
            v-else
            style="max-width: 380px; height: 100px"
            class="d-flex flex-column justify-center"
          >
            <div class="mx-auto">비회원은 주문서 업로드가 불가합니다.</div>
            <div class="mx-auto">
              다음 버튼을 눌러 주문 접수 과정을 둘러볼 수 있습니다.
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column" style="gap: 20px">
        <div class="d-flex flex-column" style="gap: 8px">
          <div
            class="font-weight-bold"
            :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'"
          >
            2. 추가 정보 <span class="caption">(선택)</span>
          </div>
          <div class="subtitle-2 px-1 onSurface_normal--text">
            특정 메이커스페이스 이용 시 관련 정보가 필요할 수 있습니다. (ID,
            학번 등)
          </div>
        </div>
        <div class="d-flex flex-column px-3" style="gap: 8px">
          <div
            class="d-flex py-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="justify-content: space-between; max-width: 380px"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
            >
              메이커스페이스
            </div>
            <v-autocomplete
              v-model="orderData.workspace"
              :items="schoolList"
              cache-items
              single-line
              hide-no-data
              hide-details
              dense
              outlined
              style="max-width: 240px"
            />
          </div>
          <div
            class="d-flex py-2"
            :class="
              $vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'
            "
            style="justify-content: space-between; max-width: 380px"
          >
            <div
              :class="
                $vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'
              "
            >
              메이커 ID
            </div>
            <v-text-field
              v-model="orderData.makerId"
              dense
              outlined
              hide-details
              style="max-width: 240px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/utils/constants";

export default {
  props: { step: String, orderData: Object },
  data() {
    return {
      schoolList: constants.schoolList,
    };
  },
  methods: {},
};
</script>

<style></style>
