<template>
  <div
    class="d-flex"
    :style="$vuetify.breakpoint.smAndDown ? 'gap: 56px' : 'gap: 20px'"
    :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'px-3'"
  >
    <div
      class="d-flex flex-column"
      style="gap: 32px"
      :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'"
    >
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 16px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 4px">
          <div
            class="font-weight-bold onSurface_dark--text"
            :class="$vuetify.breakpoint.mdAndUp ? 'title-1' : 'subtitle-1'"
          >
            아래에 작업 내용을 작성하세요.
          </div>
          <div
            class="subtitle-2 px-1 font-weight-regular onSurface_light--text"
          >
            치수와 같은 정보는 텍스트가 아닌, 첨부파일 (스케치)에 작성해주세요.
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 160px;
            border: 1px solid var(--v-background_dark-base) !important;
            position: relative;
            overflow: hidden;
            border-radius: 4px;
          "
        >
          <textarea
            v-model="modelingMemo"
            rows="8"
            style="
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              resize: none;
              padding: 10px;
              box-sizing: border-box;
            "
            @click="
              modelingMemo == '이곳에 내용을 입력하세요.' && (modelingMemo = '')
            "
            @input="checkInput"
          />
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :style="$vuetify.breakpoint.smAndDown ? 'gap: 16px' : 'gap: 20px'"
      >
        <div class="d-flex flex-column" style="gap: 4px">
          <div
            class="font-weight-bold onSurface_dark--text"
            :class="$vuetify.breakpoint.mdAndUp ? 'title-1' : 'subtitle-1'"
          >
            아래에 파일을 추가하세요.
          </div>
          <div
            class="subtitle-2 px-1 font-weight-regular onSurface_light--text"
          >
            도면 스케치, 참고 사진, 단면도 스케치 등
          </div>
        </div>
        <div
          class="d-flex subtitle-2 font-weight-regular onSurface_light--text justify-space-between"
        >
          <div
            style="cursor: pointer"
            @click="
              const allSelected = orderData.modelingFile.every(
                (item) => item.selected
              );
              orderData.modelingFile.forEach(
                (item) => (item.selected = !allSelected)
              );
            "
          >
            {{
              orderData.modelingFile.length != 0
                ? orderData.modelingFile.every((item) => item.selected)
                  ? "전체 선택 해제"
                  : "전체 선택"
                : "전체 선택"
            }}
          </div>
          <div style="cursor: pointer" @click="deleteSelectedFile()">
            선택 항목 삭제
          </div>
        </div>
        <v-row
          dense
          @drop.prevent="onFileDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
        >
          <v-col
            cols="12"
            lg="6"
            v-for="item in orderData.modelingFile.filter((v) => !v.delete)"
            :key="item.name"
          >
            <div
              class="d-flex pa-2"
              :style="
                item.selected
                  ? 'border: 1px solid var(--v-primary-base)'
                  : 'border: 1px solid var(--v-background_dark-base)'
              "
              style="border-radius: 4px; gap: 8px; cursor: pointer"
              @click="
                $event.ctrlKey
                  ? (item.selected = !item.selected)
                  : item.selected
                  ? (item.selected = false)
                  : (orderData.modelingFile.forEach(
                      (i) => (i.selected = false)
                    ),
                    (item.selected = true))
              "
            >
              <div>
                <v-skeleton-loader
                  v-if="!previewImage(item)"
                  style="width: 40px; height: 40px"
                  type="image"
                />
                <v-img
                  v-else
                  style="border-radius: 4px"
                  :src="previewImage(item)"
                  max-width="40"
                  max-height="40"
                />
              </div>
              <div
                class="d-flex flex-column justify-space-around"
                style="
                  gap: 8px;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <div class="subtitle-2 font-weight-bold onSurface_dark--text">
                  {{ item.name }}
                </div>
                <div
                  class="d-flex justify-space-between subtitle-2 font-weight-regular onSurface_dark--text"
                >
                  <div>
                    {{
                      item.size >= 1024 * 1024
                        ? (item.size / 1024 / 1024).toFixed(2) + "MB"
                        : (item.size / 1024).toFixed(0) + "KB"
                    }}
                  </div>
                  <div>
                    {{ item.type.toUpperCase() }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div
              @click="$refs.uploader.click()"
              height="100%"
              :style="{
                backgroundColor: dragover ? 'rgba(0, 0, 0, 0.05)' : '',
              }"
              class="d-flex flex-column text-center pa-2"
              style="
                border: 2px dashed var(--v-primary-base);
                border-radius: 4px;
                gap: 4px;
                cursor: pointer;
              "
            >
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="onFileInput"
                multiple
              />
              <div class="subtitle-2 font-weight-bold">새 파일 업로드</div>
              <div class="mx-auto subtitle-2 text-center">
                클릭 또는 드래그로 내 PC 파일 업로드
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div
      class="d-flex flex-column"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'width: 100%; gap: 16px'
          : 'width: 50%; gap: 32px'
      "
    >
      <div class="title-2 font-weight-bold onSurface_dark--text">
        모델링 의뢰 자료 첨부 Tip!
      </div>
      <v-row dense>
        <v-col cols="12" lg="6" v-for="item in tips" :key="item.title">
          <div
            class="d-flex flex-column pa-2"
            style="
              border: 1px solid var(--v-background_dark-base);
              border-radius: 4px;
              gap: 12px;
              cursor: pointer;
            "
            @click="$toasted.global.notice('게시글 준비중입니다')"
          >
            <div>
              <v-skeleton-loader
                v-if="!item.previewImg"
                style="width: 100%; height: 130px"
                type="image"
              />
              <v-img
                v-else
                :src="item.previewImg"
                max-width="100%"
                max-height="130px"
              />
            </div>
            <div class="d-flex flex-column" style="gap: 4px">
              <div class="subtitle-1 font-weight-bold">
                {{ item.title }}
              </div>
              <div class="body-2">
                {{ item.content }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" style="height: 100%" />
    </div>
  </div>
</template>

<script>
import { createPreviewImage } from "@/API/modelUtils.js";
export default {
  props: { step: String, orderData: Object },
  data() {
    return {
      modelingMemo: "",

      dragover: false,
      tips: [
        {
          title: "단순 회전체 만들기",
          content: "컵 몰드, 손잡이 부품 등 단순 회전체 제작 예시!",
        },
        {
          title: "두께만 추가된 간단한 모델링",
          content: "dxf, svg, png 파일을 이용한 단순 두께 작업 예시!",
        },
        {
          title: "기존 모델 치수 변형",
          content: "stl, stp 등 3D파일의 치수나 형상을 변형하는 작업 예시!",
        },
        {
          title: "발명대회 모델링 작업",
          content:
            "구체적인 스케치 없이 메이드올에 전적으로 맡기는 프로젝트 작업 예시!",
        },
      ],
    };
  },
  mounted() {
    this.modelingMemo = this.orderData.modelingMemo || "이곳에 내용을 입력하세요.";
  },
  methods: {
    checkInput() {
      if (this.modelingMemo != "이곳에 내용을 입력하세요.") {
        this.orderData.modelingMemo = this.modelingMemo;
      }
    },
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    // 일반 인풋 파일 인식
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
      this.$refs.uploader.value = "";
    },
    addFile(v) {
      const fileType = v.name.slice(v.name.lastIndexOf(".") + 1).toLowerCase();
      if (["3dm", "skp", "gcode", "ply", "hfb"].includes(fileType)) {
        this.$toasted.global.error(fileType + " 파일은 확인이 불가합니다.");
        return;
      }
      if (v.size >= 50000000) {
        this.$toasted.global.error(`50MB 이하 파일만 등록 가능합니다.`);
        return;
      }
      const fileNameExists = this.orderData.modelingFile.some(
        (file) => file.name === v.name
      );
      if (fileNameExists) {
        this.$toasted.global.error(`이미 같은 이름의 파일이 존재합니다.`);
        return;
      }
      const file = {
        selected: true,
        name: v.name,
        size: v.size,
        previewImg: "",
        url: URL.createObjectURL(v),
        type: fileType,
      };
      this.orderData.modelingFile = this.orderData.modelingFile.map((file) => ({
        ...file,
        selected: false,
      }));
      this.orderData.modelingFile.unshift(file);
      if (["stl", "obj", "3mf"].includes(fileType)) {
        this.updateModelDetails(file);
      }
    },
    async updateModelDetails(file) {
      const previewImg = await createPreviewImage(file);
      const fileToUpdate = this.orderData.modelingFile.find(
        (f) => f.name === file.name
      );
      if (fileToUpdate) {
        fileToUpdate.previewImg = previewImg;
      }
    },
    previewImage(item) {
      if (item.previewImg) {
        return item.previewImg;
      } else {
        const fileType = item.name
          .slice(item.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (["png", "jpg", "jpeg", "gif", "bmp", "webp"].includes(fileType)) {
          return item.url;
        }
        if (fileType == "pdf") {
          return "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FPDF_thumbnail.png?alt=media&token=1cc80cdb-6e88-432d-b7ba-e8a0b6ac8c80";
        }
        return null;
      }
    },
    async deleteSelectedFile() {
      this.orderData.modelingFile.forEach((item) => {
        if (item.selected) {
          this.$set(item, "delete", true);
        }
      });
    },
  },
};
</script>

<style></style>
