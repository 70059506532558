import Vue from "vue";
import firestorageUtils from "@/utils/firestorage";
import _ from "lodash";

/**
 * 주문서 데이터를 전처리 (Timestamp를 Date 객체로 변환)
 * @param {Object} order - 주문서 데이터
 * @returns {Object|null} - 전처리된 주문서 데이터
 */
function preprocessOrder(order) {
  if (!order) return null;
  ["createdAt", "dueAt", "paidAt", "shipAt", "updatedAt"].forEach(
    (key) => order[key]?.toDate && (order[key] = order[key].toDate())
  );
  return order;
}

export default {
  /**
   * Firestore에 새로운 주문서를 생성하고 저장
   * @param {Object} order - 사용자 정보 (uid, displayName, phoneNumber 포함)
   * @returns {Promise<Object|null>} - 생성된 주문서 데이터 (전처리 포함) 또는 null
   */
  async createOrder(order) {
    try {
      const docRef = Vue.prototype.$firebase
        .firestore()
        .collection("orders")
        .doc();
      const newOrderId = docRef.id;

      // 기본 주문서 데이터
      const newOrderData = {
        ...order,
        id: newOrderId,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Firestore에 데이터 저장
      await docRef.set(newOrderData);
      console.log(`Order created with ID: ${newOrderId}`);

      // Firestore에서 저장된 데이터 불러오기 및 전처리
      const savedDoc = await docRef.get();
      const savedOrder = preprocessOrder(savedDoc.data());

      return savedOrder;
    } catch (error) {
      console.error("주문서를 생성하는 중 오류가 발생했습니다:", error);
      return null;
    }
  },
  /**
   * 조건에 맞는 주문서를 검색하고 데이터를 반환
   * @param {Object} params - 검색 조건 ({ field, value, limit })
   * @param {function} callback - 데이터를 처리할 콜백 함수
   */
  async searchOrder({ field, value, limit = 10 }, callback) {
    if (!field || !value) {
      console.error("검색 조건이 유효하지 않습니다.");
      callback([]);
      return;
    }

    try {
      const snapshot = await Vue.prototype.$firebase
        .firestore()
        .collection("orders")
        .orderBy("createdAt", "desc")
        .where(field, "==", value)
        .limit(limit)
        .get();

      if (snapshot.empty) {
        console.warn(
          `조건에 맞는 주문서를 찾을 수 없습니다: ${field} = ${value}`
        );
        callback([]);
        return;
      }

      const orders = snapshot.docs.map((doc) => preprocessOrder(doc.data()));
      callback(orders);
    } catch (error) {
      console.error("주문서를 검색하는 중 오류가 발생했습니다:", error);
      callback([]);
    }
  },

  /**
   * 특정 주문서를 실시간으로 구독하고 데이터를 반환
   * @param {string} id - 주문서 ID
   * @param {function} callback - 데이터를 처리할 콜백 함수
   * @returns {function} - 구독 해제를 위한 unsubscribe 함수
   */
  getOrder(id, callback) {
    if (!id) {
      console.error("Order ID가 제공되지 않았습니다.");
      return () => {};
    }

    try {
      return Vue.prototype.$firebase
        .firestore()
        .collection("orders")
        .doc(id)
        .onSnapshot(
          (doc) => callback(doc.exists ? preprocessOrder(doc.data()) : null),
          (error) => console.error("구독 중 오류 발생:", error)
        );
    } catch (error) {
      console.error("주문서를 가져오는 중 오류가 발생했습니다:", error);
      return () => {};
    }
  },

  async getOrders() {},

  /**
   * 주문서를 업데이트하는 함수
   * - Firestore에 주문서 정보 업데이트
   * - blob 형태의 파일 Firestorage 업로드 후 URL 갱신
   * - 업로드 상황을 Toast로 제공
   *
   * @param {Object} order - 업데이트할 주문서 객체
   */
  async updateOrder(order) {
    try {
      // 1) 파일/모델링파일 중 'delete' 플래그가 있는 것들 먼저 삭제
      if (order.file) {
        await this.deleteFilesFromArray(order.file);
        order.file = order.file.filter((f) => !f.delete);
      }
      if (order.modelingFile) {
        await this.deleteFilesFromArray(order.modelingFile);
        order.modelingFile = order.modelingFile.filter((f) => !f.delete);
      }

      // 2) 업데이트 시간 기록
      order.updatedAt = new Date();

      // 3) file 배열에서 Blob URL로 된 파일 업로드
      const blobFiles = order.file.filter(
        (f) => typeof f.url === "string" && f.url.startsWith("blob:")
      );
      let uploadedCount = 0;

      await Promise.all(
        blobFiles.map(async (f) => {
          try {
            f.url = (
              await firestorageUtils.uploadFile({
                file: await (await fetch(f.url)).blob(),
                path: `Orders/${order.id}/printFile/${f.name}`,
              })
            ).downloadURL;

            if (f.previewImg) {
              f.previewImg = (
                await firestorageUtils.uploadFile({
                  file: await (await fetch(f.previewImg)).blob(),
                  path: `Orders/${order.id}/printFile/${f.name.replace(
                    /\.[^/.]+$/,
                    ""
                  )}_Img.png`,
                })
              ).downloadURL;
            }
            Vue.toasted.global.notice(
              `파일 업로드 중 (${++uploadedCount}/${blobFiles.length})`
            );
          } catch (err) {
            Vue.toasted.global.error("파일 업로드 실패");
            console.error("File upload failed:", err);
            this.saveBtnLoading = false;
          }
        })
      );
      // 4) modelingFile 중 Blob URL로 된 파일 업로드
      if (order.modelingFile) {
        const uploadPromisesModeling = order.modelingFile.map(
          async (file, index) => {
            if (typeof file.url === "string" && file.url.startsWith("blob:")) {
              try {
                const blob = await (await fetch(file.url)).blob();
                const result = await firestorageUtils.uploadFile({
                  file: blob,
                  path: `Orders/${order.id}/modelingFile/${file.name}`,
                });
                file.url = result.downloadURL;
                Vue.toasted.global.notice(
                  `모델링 파일 업로드 중 (${index + 1}/${
                    order.modelingFile.length
                  })`
                );
              } catch (error) {
                Vue.toasted.global.error(`파일 업로드 실패`);
                console.error("File upload failed: ", error);
              }
            }
          }
        );
        await Promise.all(uploadPromisesModeling);
      }
      // 5) 필요없는 속성 제거 후 최종 데이터 생성
      const updateData = _.cloneDeep(order);

      updateData.file.forEach((f) => {
        delete f.selected;
        delete f.delete;
        if (f.option) {
          f.option.forEach((o) => delete o.selected);
        }
      });
      if (updateData.modelingFile) {
        updateData.modelingFile.forEach((f) => {
          delete f.selected;
          delete f.delete;
        });
      }
      delete updateData.selected;

      // 6) Firestore 업데이트
      await Vue.prototype.$firebase
        .firestore()
        .collection("orders")
        .doc(updateData.id)
        .update(updateData);

      Vue.toasted.global.success("주문서가 성공적으로 저장되었습니다.");
      return order;
    } catch (error) {
      console.error("Error updating order:", error);
      Vue.toasted.global.error("주문서 저장 중 오류가 발생했습니다.");
    }
  },

  /**
   * 주어진 파일 배열에서 delete 플래그가 있는 파일을 스토리지에서 삭제
   * @param {Array} fileArray
   */
  async deleteFilesFromArray(fileArray) {
    if (!Array.isArray(fileArray)) return;
    const deleteTargets = fileArray.filter((file) => file.delete);

    const deletePromises = deleteTargets.map(async (file) => {
      try {
        if (
          file.previewImg &&
          file.previewImg.includes("firebasestorage.googleapis.com")
        ) {
          await firestorageUtils.deleteFile(file.previewImg);
        }
        if (file.url && file.url.includes("firebasestorage.googleapis.com")) {
          await firestorageUtils.deleteFile(file.url);
        }
        console.log(`Files associated with ${file.name} deleted successfully.`);
      } catch (error) {
        Vue.toasted.global.error("파일 삭제 실패");
        console.error(
          `Failed to delete file associated with ${file.name}:`,
          error
        );
      }
    });
    await Promise.all(deletePromises);
  },

  /**
   * 주문서를 Firestore에서 삭제
   * @param {Object} order - 삭제할 주문서 객체
   */
  async deleteOrder(order) {
    if (!order || !order.id) {
      Vue.toasted.global.error("주문서 정보가 유효하지 않습니다.", "error");
      return;
    }

    try {
      const collection = Vue.prototype.$firebase
        .firestore()
        .collection("orders");

      const docRef = collection.doc(order.id);
      const docSnapshot = await docRef.get();

      // 문서가 존재하는지 확인
      if (!docSnapshot.exists) {
        Vue.toasted.global.error(
          "삭제하려는 주문서가 존재하지 않습니다.",
          "error"
        );
        return;
      }

      const { claims } = await Vue.prototype.$firebase
        .auth()
        .currentUser.getIdTokenResult();
      if (!claims.user_id) {
        Vue.toasted.global.error(
          "사용자 정보를 확인할 수 없습니다. 다시 로그인해주세요.",
          "error"
        );
        return;
      }

      // Firestore에서 문서 삭제
      await docRef.delete();

      // 삭제 알림 API 호출
      if (order.channelTalk) {
        await Vue.prototype.$axios.post("notice/orderDeleted", {
          order,
          claims,
        });
      }

      Vue.toasted.global.success("주문서가 성공적으로 삭제되었습니다.");
    } catch (error) {
      console.error("Error deleting order:", error);

      if (error.code === "permission-denied") {
        Vue.toasted.global.error(
          "삭제 권한이 없습니다. 관리자에게 문의하세요.",
          "error"
        );
      } else if (error.code === "unavailable") {
        Vue.toasted.global.error(
          "네트워크 연결에 문제가 발생했습니다. 다시 시도해주세요.",
          "error"
        );
      } else {
        Vue.toasted.global.error(
          "주문서 삭제 중 알 수 없는 오류가 발생했습니다.",
          "error"
        );
      }
    }
  },
};
