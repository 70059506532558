var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'px-3',staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 1. 어떤 작업인가요? "),_c('span',{staticClass:"caption error--text"},[_vm._v("*")])]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 사용 목적을 참고하여 담당자가 출력 방식이나 소재가 적절한지 안내드립니다! ")])]),_c('div',{staticClass:"d-flex px-5 py-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''},[_c('div',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',staticStyle:{"min-width":"120px","white-space":"nowrap"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px')},[_vm._v(" 사용 목적 (환경) ")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"12px"}},[_vm._l((_vm.purposes),function(purpose){return _c('div',{key:purpose,staticClass:"d-flex align-center px-4 py-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',staticStyle:{"border-radius":"4px","height":"40px","cursor":"pointer"},style:(_vm.orderData.purpose == purpose
                ? 'border: 1px solid var(--v-primary-base)'
                : 'border: 1px solid var(--v-background_dark-base)'),on:{"click":function($event){return _vm.changePurpose(purpose)}}},[_vm._v(" "+_vm._s(purpose)+" ")])}),_c('v-input',{staticClass:"font-weight-regular px-4 py-2 d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',style:({
              borderRadius: '4px',
              maxWidth: _vm.$vuetify.breakpoint.smAndDown ? '260px' : '320px',
              height: '40px',
              border:
                _vm.purposeEtc != '기타(직접 입력)'
                  ? '1px solid var(--v-primary-base)'
                  : '1px solid var(--v-background_dark-base)',
            }),attrs:{"hide-details":"auto","dense":"","solo":"","single-line":""},on:{"click":function($event){_vm.purposeEtc == '기타(직접 입력)' &&
                ((_vm.purposeEtc = ''), (_vm.orderData.purpose = ''))}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.purposeEtc),expression:"purposeEtc"}],staticStyle:{"width":"100%"},attrs:{"type":"text"},domProps:{"value":(_vm.purposeEtc)},on:{"input":[function($event){if($event.target.composing)return;_vm.purposeEtc=$event.target.value},_vm.checkInput]}})]},proxy:true}]),model:{value:(_vm.purposeEtc),callback:function ($$v) {_vm.purposeEtc=$$v},expression:"purposeEtc"}})],2)])]),_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 2. 3D 도면 파일이 있나요? "),_c('span',{staticClass:"caption error--text"},[_vm._v("*")])]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 프린팅은 도면이 있어야 진행이 가능합니다. 도면이 없다면, 도면부터 만들어야 합니다! ")])]),_c('div',{staticClass:"d-flex px-5 py-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''},[_c('div',{staticClass:"subtitle-2 d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',staticStyle:{"width":"120px","height":"40px","white-space":"nowrap"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px')},[_vm._v(" 도면 여부 ")]),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.smAndDown && 'flex-column',staticStyle:{"gap":"12px"},style:(_vm.$vuetify.breakpoint.smAndDown && 'width: 100%')},[_c('div',{staticClass:"d-flex flex-column px-4 py-3",staticStyle:{"border-radius":"4px","gap":"4px","cursor":"pointer"},style:(_vm.orderData.workspace != '모델링'
                ? 'border: 1px solid var(--v-primary-base)'
                : 'border: 1px solid var(--v-background_dark-base)'),on:{"click":function($event){_vm.orderData.workspace = '메이드올 대구지사'}}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'},[_vm._v(" 네, 3D 설계 데이터가 있습니다. ")]),_c('div',{staticClass:"body-2"},[_vm._v("STL (권장), 3MF, OBJ, STEP, STP 등")])]),_c('div',{staticClass:"d-flex flex-column px-4 py-3",staticStyle:{"border-radius":"4px","gap":"4px","cursor":"pointer"},style:(_vm.orderData.workspace == '모델링'
                ? 'border: 1px solid var(--v-primary-base)'
                : 'border: 1px solid var(--v-background_dark-base)'),on:{"click":function($event){_vm.orderData.workspace = '모델링'}}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1'},[_vm._v(" 아니요, 도면 설계부터 필요합니다. ")]),_c('div',{staticClass:"body-2"},[_vm._v("참고사진, 도면 스케치, 2D 캐드 도면 등")])])])])]),(_vm.orderData.workspace != '모델링')?_c('div',{staticClass:"d-flex flex-column",style:(_vm.$vuetify.breakpoint.smAndDown ? 'gap: 4px' : 'gap: 20px')},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title-2'},[_vm._v(" 3. 작업 장소를 선택해주세요. "),_c('span',{staticClass:"caption error--text"},[_vm._v("*")])]),_c('div',{staticClass:"px-1 subtitle-2 onSurface_normal--text"},[_vm._v(" 각 작업 장소 별 사용 가능한 소재나 방식 등을 참고하시어 이용해 주세요! ")])]),_c('div',{staticClass:"d-flex px-5 py-2",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''},[_c('div',{staticClass:"d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1',staticStyle:{"min-width":"120px","height":"40px","white-space":"nowrap"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'height: 30px' : 'height: 40px')},[_vm._v(" 메이커스페이스 ")]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"12px","width":"100%"}},_vm._l((_vm.workspaces),function(workspace){return _c('div',{key:workspace.name,staticClass:"d-flex px-4 py-3",staticStyle:{"border-radius":"4px","gap":"12px","width":"100%","cursor":"pointer"},style:(_vm.orderData.workspace == workspace.name
                ? 'border: 1px solid var(--v-primary-base)'
                : 'border: 1px solid var(--v-background_dark-base)'),on:{"click":function($event){return _vm.changeWorkspace(workspace)}}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',[(!workspace.img)?_c('v-skeleton-loader',{staticStyle:{"width":"56px","height":"56px"},attrs:{"type":"image"}}):_c('v-img',{attrs:{"src":workspace.img,"max-width":"56","max-height":"56"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"8px","width":"100%"}},[_c('div',{staticStyle:{"gap":"8px"}},[_c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"space-between"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
                          ? 'subtitle-2'
                          : 'subtitle-1'},[_vm._v(" "+_vm._s(workspace.name)+" ")]),_c('div',{staticClass:"subtitle-2",on:{"click":function($event){return _vm.$toasted.global.notice('게시글 준비중입니다')}}},[_vm._v(" 자세히 보기 + ")])]),_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2'},[_vm._v(" "+_vm._s(workspace.address)+" ")])])]),_c('div',{class:_vm.$vuetify.breakpoint.smAndDown ? 'caption' : 'body-2'},[_vm._v(" "+_vm._s(workspace.description)+" ")])])])}),0)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }